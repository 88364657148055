import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import * as ActiveStorage from '@rails/activestorage';
import Channels from 'channels';
import cocoonVanilaJs from '@kollegorna/cocoon-vanilla-js';
import debounce from 'debounce';
import setUpCustomConfirm from './customConfirm';
import UIKit from './libs/uikit.js';
import Notifications from './notifications';
import Utilities from './utilities';
import Modal from './modal';
import Pagination from './pagination';

Turbolinks.start();
ActiveStorage.start();

const utilities = new Utilities();
const notifications = new Notifications();
const modal = new Modal();
const pagination = new Pagination();

window.UIKit = UIKit;
window.Notifications = notifications;
window.Modal = modal;
window.Utilities = utilities;
window.Pagination = pagination;
window.debounce = debounce;
window.Rails = Rails;
setUpCustomConfirm(Rails, Rails.start);


document.addEventListener('turbolinks:load', () => {
  utilities.toggleSidebarButtonHandler();
  utilities.toggleSidebarResizeHandler();
  utilities.togglePassword();
  utilities.enableDatepickers();
  utilities.enableSelects();
  utilities.handleToggleChange();
  utilities.filterDefaultCheckboxes();
  pagination.scrollHandler();
  pagination.filterHandler();
});
