import datepicker from 'js-datepicker';
import Choices from 'choices.js';
import { BREAKPOINTS } from './config';

class Utilities {
  toggleSidebarButtonHandler() {
    const toggleButton = document.getElementsByClassName('js-toggle-menu')[0];
    const appRoot = document.getElementsByClassName('js-app-root')[0];
    if (!toggleButton) return;
    toggleButton.addEventListener('click', () => appRoot.classList.toggle('hidden-sidebar'));
  }

  toggleSidebarResizeHandler() {
    const appRoot = document.getElementsByClassName('js-app-root')[0];
    if (!appRoot) return;
    if (window.innerWidth < BREAKPOINTS.medium) {
      appRoot.classList.add('hidden-sidebar');
    }
    window.addEventListener('resize', () => {
      if (window.innerWidth < BREAKPOINTS.medium) {
        appRoot.classList.add('hidden-sidebar');
      } else {
        appRoot.classList.remove('hidden-sidebar');
      }
    })
  }

  togglePassword() {
    const containers = document.getElementsByClassName('js-password-input');
    [...containers].forEach(container => {
      const input = container.getElementsByTagName('input')[0];
      const icons = container.getElementsByTagName('svg');
      container.addEventListener('click', ev => {
        if (ev.target.tagName !== 'svg') return;
        input.type = input.type === 'password' ? 'text' : 'password';
        [...icons].forEach(icon => icon.classList.toggle('uk-hidden'));
      });
    });
  }

  enableDatepickers() {
    const containers = document.getElementsByClassName('js-datepicker');
    [...containers].forEach(container => {
      const translations = JSON.parse(container.dataset.translations);
      datepicker(container, {
        startDay: 1,
        disableYearOverlay: true,
        customDays: translations.days,
        customMonths: translations.months,
        formatter: (input, date) => {
          const dateFormat = input.dataset.format;
          const dateObject = {
            '%d': date.getDate(),
            '%m': date.getMonth() + 1,
            '%Y': date.getFullYear()
          }
          input.value = dateFormat.replace(/%d|%m|%Y/gi, matched => dateObject[matched]);
        }
      })
    })
  }

  enableSelects() {
    const containers = document.querySelectorAll('.js-select:not([data-initialized="true"])');
    [...containers].forEach(container => {
      const label = container.getElementsByTagName('label')[0];
      const select = container.getElementsByTagName('select')[0];
      const choices = new Choices(select, {
        searchEnabled: false,
        placeholder: false,
        itemSelectText: '',
        callbackOnInit: () => { container.dataset.initialized = true; }
      });
      choices.passedElement.element.addEventListener('choice', () => label.classList.add('active'));
      if (choices._isSelectMultipleElement) {
        selectMultipleHandlers(choices, label);
      } else {
        if (choices.getValue() && choices.getValue().value) label.classList.add('active');
      }
    });
  }

  handleToggleChange() {
    [...document.querySelectorAll('*[uk-toggle]')].forEach(el => {
      el.addEventListener('click', ev => ev.currentTarget.classList.toggle('uk-open'));
    })
  }

  parseDOM(content, children=false) {
    const domParser = new DOMParser();
    const parsedContent = domParser.parseFromString(content, 'text/html');
    return children ? parsedContent.body.children : parsedContent.body.firstChild;
  }

  filterDefaultCheckboxes() {
    [...document.getElementsByClassName('js-checkbox-filter')].forEach(container => {
      const defaultInput = container.querySelector('[value=""]');
      defaultInput.checked = true;
      container.addEventListener('click', ev => {
        if (ev.target == defaultInput) {
          container.querySelectorAll('input:not([value=""]):checked').forEach(input => input.checked = false)
        }
        if (ev.target.checked) {
          if (ev.target.value) defaultInput.checked = false;
        } else {
          if (container.querySelectorAll('input:checked').length == 0) defaultInput.checked = true;
        }
      })
    });
  }

  addReportHandler(prevUrl, nextUrl) {
    document.querySelectorAll('.js-save-button').forEach(button => {
      button.addEventListener('click', ev => {
        const nextAction = ev.target.dataset.step;
        const form = document.querySelector('.js-save-form');
        if (!form) return noFormSwitch(nextAction, prevUrl, nextUrl);
        if (nextAction) form.append(setNextAction(nextAction));
        Rails.fire(form, 'submit');
      });
    });
  }
}

const selectMultipleHandlers = (choices, label) => {
  if (choices.getValue().length) label.classList.add('active');
  choices.input.element.addEventListener('input', ev => {
    if (ev.target.value.length || choices.getValue().length) {
      label.classList.add('active')
    } else {
      label.classList.remove('active')
    }
  });
  choices.passedElement.element.addEventListener('highlightItem', () => {
    choices.removeHighlightedItems();
    if (!choices.getValue().length) label.classList.remove('active');
  });
}

const setNextAction = step => {
  const input = document.createElement('input');
  input.type = 'hidden';
  input.name = 'change_step';
  input.value = step;
  return input;
}

const noFormSwitch = (step, prevUrl, nextUrl) => {
  if (step === 'prev_step') window.location = prevUrl;
  if (step === 'next_step') window.location = nextUrl;
}

export const parseDOM = Utilities.prototype.parseDOM;
export default Utilities;
