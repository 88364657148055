class Notifications {
  display(message, status) {
    UIKit.notification({ message, status, pos: 'bottom-right', timeout: 5000 });
  }

  changeSidebarCount(elem, count) {
    var elem = document.getElementById(elem);
    if (!elem) return;

    elem.hidden = !count
    elem.innerText = count;
  }
}

export default Notifications;
