import consumer from "./consumer"

consumer.subscriptions.create("InformationChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    switch(data.type) {
      case 'new_report':
        this.newReport(data.count);
        break;
      case 'new_worker':
        this.newWorker(data.count);
        break;
      case 'worker_new_report':
        this.workerNewReport(data.count)
        break;
      default:
        console.log('OK')
    }
  },

  workerNewReport(count) {
    Notifications.changeSidebarCount('js-reports-obligations-count', count)
  },

  newReport(count) {
    Notifications.changeSidebarCount('js-reports-list-count', count)
  },

  newWorker(count) {
    Notifications.changeSidebarCount('js-unconfirmed-workers-count', count)
  }
});
