import closeIcon from 'images/icons/close.svg';

let Rails;
const translations = {
  pl: {
    confirm: 'Zatwierdź',
    cancel: 'Anuluj',
    warning: 'Uwaga'
  },
  en: {
    confirm: 'Confirm',
    cancel: 'Cancel',
    warning: 'Warning'
  }
}

const customModal = (message, opts) => {
  const options = Object.assign({ stack: true, bgClose: false, escClose: true, labels: UIKit.modal.labels }, opts);
  return new Promise((resolve, reject) => {
    const modal = UIKit.modal.dialog(`
      <form class="prime-modal">
        <div class="uk-modal-header uk-flex uk-flex-between uk-flex-middle">
          <span class="uk-text-bold font-size__m">${options.title}</span>
          <img src="${closeIcon}" class="icon__small uk-modal-close uk-margin-small-left" />
        </div>
        <div class="uk-modal-body">
          <span>${message}</span>
          <div class="uk-flex uk-flex-wrap uk-flex-right uk-margin-top">
            <button class="prime-button white uk-modal-close">${options.labels.cancel}</button>
            <button type="submit" class="prime-button orange uk-margin-left">${options.labels.ok}</button>
          </div>
        </div>
      </form>
    `, options)

    let resolved = false;

    modal.$el.getElementsByTagName('form')[0].addEventListener('submit', ev => {
      ev.preventDefault();
      resolve();
      resolved = true;
      modal.hide();
    });

    UIKit.util.on(modal.$el, 'hide', () => {
      if (!resolved) reject();
    })
  })
}

const handleConfirm = function(el) {
  if (!allowAction(this)) Rails.stopEverything(el);
}

const allowAction = el => {
  if (el.dataset.uiConfirm === null) return true;
  showConfirmationDialog(el);
  return false;
}

const showConfirmationDialog = el => {
  const message = el.dataset.uiConfirm;
  const lang = document.body.dataset.language || 'pl';

  const options = {
    title: el.dataset.uiTitle || translations[lang].warning,
    labels: {
      ok: translations[lang].confirm,
      cancel: translations[lang].cancel
    }
  }

  customModal(message, options)
    .then(() => confirmed(el))
    .catch(() => {});
}

const confirmed = el => {
  el.removeAttribute('data-ui-confirm');
  el.click();
}

const setUpCustomConfirm = (railsObject, railsStart) => {
  Rails = railsObject;

  document.addEventListener('rails:attachBindings', el => {
    Rails.delegate(document, 'a[data-ui-confirm], button[data-ui-confirm], input[data-ui-confirm]', 'click', handleConfirm);
  });

  if(Rails.fire(document, 'rails:attachBindings')) railsStart();
}

export default setUpCustomConfirm;
