import { parseDOM } from './utilities';

class Modal {
  addModalWithForm(modalId, formId, partialString) {
    const modal = document.getElementById(modalId);
    const form = document.getElementById(formId);
    const partial = parseDOM(partialString);

    if (modal) {
      form.outerHTML = partial.querySelector(`#${formId}`).outerHTML;
    } else {
      document.body.appendChild(partial);
      UIKit.modal(`#${modalId}`).hide();
    }
    window.Utilities.togglePassword();
  }

  toggleModal(modalId) {
    const modal = document.getElementById(modalId);
    if ([...modal.classList].includes('uk-open')) {
      UIKit.modal(modal).hide();
    } else {
      UIKit.modal(modal).show();
    }
  }
}

export default Modal;
