import debounce from 'debounce';
import { parseDOM } from './utilities';
import { SCROLL_OFFSET } from './config';

class Pagination {
  scrollHandler() {
    const mainContainer = document.getElementsByClassName('js-scroll-pagination')[0];
    let link = document.getElementsByClassName('js-scroll-pagination-link')[0];
    if (!mainContainer) return;
    mainContainer.addEventListener('scroll', debounce(() => {
      link = document.getElementsByClassName('js-scroll-pagination-link')[0];
      if (mainContainer.offsetHeight + mainContainer.scrollTop < mainContainer.scrollHeight - SCROLL_OFFSET) return;
      if (!link || link.classList.contains('disabled')) return;

      link.classList.add('disabled');
      link.click();
      link.remove();
    }, 200));
    this.removeLinkHandler(link);
  }

  appendContent(partials, link) {
    const parsedPartials = parseDOM(partials, true);
    const parsedLink = parseDOM(link);

    document.getElementsByClassName('js-scroll-pagination-content')[0].append(...parsedPartials);
    if (link) document.getElementsByClassName('js-scroll-pagination-content')[0].append(parsedLink);
    this.removeLinkHandler(parsedLink);
  }

  removeLinkHandler(link) {
    if(!link) return;
    link.addEventListener('click', () => link.remove());
  }

  filterHandler() {
    [...document.getElementsByClassName('js-filter-handler')].forEach(container => {
      container.addEventListener('show', () => {
        const allInputs = container.children[0];
        if (allInputs.childElementCount > 1) return;

        fetchFilters(container, allInputs)
        const _listener = debounce(() => {
          if (container.offsetHeight + container.scrollTop < container.scrollHeight - 100) return;
          fetchFilters(container, allInputs, _listener);
        }, 200)
        container.addEventListener('scroll', _listener);
      });
    });
  }
}

const createFilterInput = (container, [value, text]) => {
  const template = container.getElementsByTagName('label')[0].cloneNode(true);
  const id = template.getAttribute('for') + value;
  template.setAttribute('for', id);
  template.children[0].setAttribute('id', id);
  template.children[0].setAttribute('value', value);
  template.children[0].checked = false;
  template.children[1].innerHTML = text;
  return template;
}

const fetchFilters = (container, allInputs, listener) => {
  const path = container.dataset.path;
  const page = parseInt(container.dataset.page, 10);
  const paginationPath = isNaN(page) ? path : path + `?page=${page + 1}`
  fetch(paginationPath)
    .then(res => res.json())
    .then(data => {
      const inputs = data.result.map(filter => createFilterInput(container, filter));
      allInputs.append(...inputs);
      container.dataset.page = data.page;
      if (data.is_last_page) container.removeEventListener('scroll', listener);
    })
    .catch(err => console.error(err));
}

export default Pagination;
